<template>
  <div class="period-budget-report-full-year-comparison-detailed-table">

    <b-container fluid class="period-budget-report-comparison-year-table-wrapper px-0 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col lg="8">
                  <h2>{{ $t(translationPath + 'title_income_categories_comparison', { period_start: startDate, period_end: endDate, comparison_year: comparisonYear}) }}</h2>
                </b-col>
                <b-col lg="4">
                  <toggle-budgets-dropdown :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <p>{{ $t(translationPath + 'description_income_categories_detailed') }}</p>
            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              responsive
              striped
              stacked="md"
              thead-class="d-none"
              :items="tableDataCollection['income']"
              :fields="fieldValues"
              :tbodyTrClass="tbodyTrClass"
              :busy="busyState"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'subtotal'">
                  {{ $t(translationPath + 'subtotal',{category_group: row.item.total_group_title})}}
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t(translationPath + 'sum_incomes')}}
                </template>
                <template v-else-if="row.item.type === 'account_group'">
                  <span style="font-size:125%">{{ row.value }}</span>
                </template>
                <template v-else>
                  <b-link :to="'/reports/performance/accounts/'+row.item.account_id + '/view/twelve-months-rolling'">{{ row.value }}</b-link>
                </template>
              </template>
              <template v-slot:cell(budget_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'budget_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else-if="row.item.type === 'accounts'">
                  <b-link :to="'/budget/account/'+row.item.account_id">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.type === 'subtotal' || row.item.type === 'total'">
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(actual_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'actual_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else-if="row.item.type === 'accounts'">
                  <b-link :to="'/transactions/view?account_id='+row.item.account_id+'&start_date='+startDate+'&end_date='+endDate">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.type === 'subtotal' || row.item.type === 'total'">
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'diff_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  <span :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.value }}</span>
                </template>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'diff_percentage') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  <template v-if="row.item.budget_amount != 0">
                    <span :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.value }}</span>
                  </template>
                  <template v-else>
                    --
                  </template>
                </template>
              </template>
              <template v-slot:cell(full_year_budget_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_budget_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(full_year_budget_percentage_of_full_year)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_budget_percentage_of_full_year') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(full_year_prognosis)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_prognosis') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="period-budget-report-comparison-year-table-wrapper px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'title_expense_categories', {
                period_start: startDate,
                period_end: endDate
              }) }}</h2>
            </template>

            <p>{{ $t(translationPath + 'description_expenses_categories_detailed') }}</p>
            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              responsive
              striped
              thead-class="d-none"
              stacked="md"
              :items="tableDataCollection['expense']"
              :fields="fieldValues"
              :tbodyTrClass="tbodyTrClass"
              :busy="busyState"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'subtotal'">
                  {{ $t(translationPath + 'subtotal',{category_group: row.item.total_group_title})}}
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t(translationPath + 'sum_expenses')}}
                </template>
                <template v-else-if="row.item.type === 'account_group'">
                  <span style="font-size:125%">{{ row.value }}</span>
                </template>
                <template v-else>
                  <b-link :to="'/reports/performance/accounts/'+row.item.account_id + '/view/twelve-months-rolling'">{{ row.value }}</b-link>
                </template>
              </template>
              <template v-slot:cell(actual_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'actual_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else-if="row.item.type === 'accounts'">
                  <b-link :to="'/transactions/view?account_id='+row.item.account_id+'&start_date='+startDate+'&end_date='+endDate">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.type === 'subtotal' || row.item.type === 'total'">
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(budget_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'budget_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else-if="row.item.type === 'accounts'">
                  <b-link :to="'/budget/account/'+row.item.account_id">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.type === 'subtotal' || row.item.type === 'total'">
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'diff_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  <span :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.value }}</span>
                </template>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'diff_percentage') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  <template v-if="row.item.budget_amount != 0">
                    <span :class="row.unformatted > 0 ?  'text-red' : 'text-green'"> {{ row.value }}</span>
                  </template>
                  <template v-else>
                    --
                  </template>
                </template>
              </template>
              <template v-slot:cell(full_year_budget_amount)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_budget_amount') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(full_year_budget_percentage_of_full_year)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_budget_percentage_of_full_year') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
              <template v-slot:cell(full_year_prognosis)="row">
                <template v-if="row.item.type === 'account_group'">
                  <span class="text-small">{{ $t(translationPath + 'full_year_prognosis') }}</span>
                </template>
                <template v-else-if="row.item.type === 'empty_row'"></template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="period-budget-report-comparison-year-table-wrapper px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'title_accounts', {
                period_start: startDate,
                period_end: endDate
              }) }}</h2>
            </template>

            <p>{{ $t(translationPath + 'description_totals') }}</p>
            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              responsive
              striped
              stacked="md"
              :items="tableDataCollection['summary']"
              :fields="fieldValues"
              :tbodyTrClass="tbodyTrClass"
              :busy="busyState"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t('common.result')}}
                </template>
                <template v-else>
                  {{ $t(`common.${row.value}`+'s') }}
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <span v-if="row.item.account_title == 'expense'" :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.value }}</span>
                <span v-else :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.value }}</span>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <span v-if="row.item.account_title == 'expense'" :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
                <span v-else :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'PeriodBudgetReportFullYearComparisonDetailedTable',
  components: {
    Loader,
    ToggleBudgetsDropdown
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    comparisonYear: { type: String, default: null },
    busyState: { type: Boolean, default: false },
    apiData: {
      type: Object,
      default: function () {
        return {
          income: null,
          expense: null,
          totals: {
            actual_amount: 0,
            budget_amount: 0,
            diff_amount: 0,
            diff_percentage: 0,
            full_year_budget_amount: 0,
            full_year_budget_percentage_of_full_year: 0,
            full_year_prognosis: 0
          }
        }
      }
    },
    budgets: {
      type: Array,
      default: () => { return [] }
    },
    budgetId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.period.table.',
      tableDataCollection: {
        income: null,
        expense: null,
        summary: null
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'account_title', label: this.currentUser.strict_accounting_mode ? this.$t('common.account') : this.$t('common.category'), sortable: false, class: 'text-left' },
        { key: 'budget_amount', label: this.$t(this.translationPath + 'budget_amount_period'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'actual_amount', label: this.$t(this.translationPath + 'actual_amount_period'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'diff_amount', label: this.$t(this.translationPath + 'diff_amount_period'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'diff_percentage', label: this.$t(this.translationPath + 'diff_percentage_period'), sortable: false, class: 'text-left text-md-right', formatter: this.percentageFormatter },
        { key: 'full_year_budget_amount', label: this.$t(this.translationPath + 'full_year_budget_amount', { year: this.comparisonYear }), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'full_year_budget_percentage_of_full_year', label: this.$t(this.translationPath + 'full_year_budget_percentage_of_full_year'), sortable: false, class: 'text-left text-md-right', formatter: this.percentageFormatter },
        { key: 'full_year_prognosis', label: this.$t(this.translationPath + 'full_year_prognosis'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor }
      ]
    },
    local () {
      return this.currentUser.default_lang
    }
  },
  methods: {
    tbodyTrClass (item) {
      if (!item) {
        return ''
      }

      if (Object.prototype.hasOwnProperty.call(item, 'type')) {
        return 'tr-' + item.type.replace(/_/, '-')
      }

      return ''
    },
    amountFormattor (value) {
      return value ? formatAmount(
        value,
        this.currentCOA.locale,
        this.currentCOA.currency,
        this.currentCOA.currency_iso,
        false,
        0
      ) : 0
    },
    percentageFormatter (value) {
      return (value * 100).toFixed(2) + '%'
    },
    onBudgetChange (budgetId) {
      this.$emit('on-budget-change', budgetId)
    }
  },
  watch: {
    apiData: {
      handler: function (value) {
        if (!value) return
        const { income, expense, totals } = value
        const self = this
        let incomeData = []
        let expenseData = []
        const summaryData = []
        const incomeInitialData = income ? Object.assign({}, income) : {
          groups: {}
        }
        const expenseInitialData = expense ? Object.assign({}, expense) : {
          groups: {}
        }
        if (income) {
          incomeInitialData.groups = Object.keys(incomeInitialData.groups).map((el) => incomeInitialData.groups[el]).sort(function (a, b) {
            const nameA = a.group_title
            const nameB = b.group_title
            return nameA.localeCompare(nameB, self.local)
          })
          Object.keys(incomeInitialData.groups).forEach(function (groupItem) {
            if (Object.prototype.hasOwnProperty.call(incomeInitialData.groups[groupItem], 'accounts')) {
              incomeData.push({
                account_title: incomeInitialData.groups[groupItem].group_title,
                group_id: incomeInitialData.groups[groupItem].group_id,
                type: 'account_group',
                actual_amount: 0,
                budget_amount: 0,
                diff_amount: 0,
                diff_percentage: 0
              })
              let accountGroups = []
              Object.keys(incomeInitialData.groups[groupItem].accounts).forEach(function (groupItemAcc) {
                accountGroups.push(Object.assign({},
                  incomeInitialData.groups[groupItem].accounts[groupItemAcc],
                  {
                    type: 'accounts'
                  }
                ))
              })
              accountGroups = accountGroups.sort(function (a, b) {
                const nameA = a.account_title
                const nameB = b.account_title
                return nameA.localeCompare(nameB, self.local)
              })
              incomeData = [...incomeData, ...accountGroups, {
                account_title: 'subtotal',
                type: 'subtotal',
                total_group_title: incomeInitialData.groups[groupItem].group_title,
                actual_amount: incomeInitialData.groups[groupItem].totals.actual_amount,
                budget_amount: incomeInitialData.groups[groupItem].totals.budget_amount,
                diff_amount: incomeInitialData.groups[groupItem].totals.diff_amount,
                diff_percentage: incomeInitialData.groups[groupItem].totals.diff_percentage,
                full_year_budget_amount: incomeInitialData.groups[groupItem].totals.full_year_budget_amount,
                full_year_budget_percentage_of_full_year: incomeInitialData.groups[groupItem].totals.full_year_budget_percentage_of_full_year,
                full_year_prognosis: incomeInitialData.groups[groupItem].totals.full_year_prognosis
              },
              {
                account_title: 'empty_row',
                type: 'empty_row',
                actual_amount: 0,
                budget_amount: 0,
                diff_amount: 0,
                diff_percentage: 0
              }]
            }
          })
          incomeData.push({
            account_title: 'total',
            type: 'total',
            actual_amount: income.totals.actual_amount,
            budget_amount: income.totals.budget_amount,
            diff_amount: income.totals.diff_amount,
            diff_percentage: income.totals.diff_percentage,
            full_year_budget_amount: income.totals.full_year_budget_amount,
            full_year_budget_percentage_of_full_year: income.totals.full_year_budget_percentage_of_full_year,
            full_year_prognosis: income.totals.full_year_prognosis
          })
        }
        if (expense) {
          expenseInitialData.groups = Object.keys(expenseInitialData.groups).map((el) => expenseInitialData.groups[el]).sort(function (a, b) {
            const nameA = a.group_title
            const nameB = b.group_title
            return nameA.localeCompare(nameB, self.local)
          })
          Object.keys(expenseInitialData.groups).forEach(function (groupItem) {
            if (Object.prototype.hasOwnProperty.call(expenseInitialData.groups[groupItem], 'accounts')) {
              expenseData.push({
                account_title: expenseInitialData.groups[groupItem].group_title,
                group_id: expenseInitialData.groups[groupItem].group_id,
                type: 'account_group',
                actual_amount: 0,
                budget_amount: 0,
                diff_amount: 0,
                diff_percentage: 0
              })
              let accountGroups = []
              Object.keys(expenseInitialData.groups[groupItem].accounts).forEach(function (groupItemAcc) {
                accountGroups.push(Object.assign({},
                  expenseInitialData.groups[groupItem].accounts[groupItemAcc],
                  {
                    type: 'accounts'
                  }
                ))
              })
              accountGroups = accountGroups.sort(function (a, b) {
                const nameA = a.account_title
                const nameB = b.account_title
                return nameA.localeCompare(nameB, self.local)
              })
              expenseData = [...expenseData, ...accountGroups, {
                account_title: 'subtotal',
                type: 'subtotal',
                total_group_title: expenseInitialData.groups[groupItem].group_title,
                actual_amount: expenseInitialData.groups[groupItem].totals.actual_amount,
                budget_amount: expenseInitialData.groups[groupItem].totals.budget_amount,
                diff_amount: expenseInitialData.groups[groupItem].totals.diff_amount,
                diff_percentage: expenseInitialData.groups[groupItem].totals.diff_percentage,
                full_year_budget_amount: expenseInitialData.groups[groupItem].totals.full_year_budget_amount,
                full_year_budget_percentage_of_full_year: expenseInitialData.groups[groupItem].totals.full_year_budget_percentage_of_full_year,
                full_year_prognosis: expenseInitialData.groups[groupItem].totals.full_year_prognosis
              },
              {
                account_title: 'empty_row',
                type: 'empty_row',
                actual_amount: 0,
                budget_amount: 0,
                diff_amount: 0,
                diff_percentage: 0
              }]
            }
          })
          expenseData.push({
            account_title: 'total',
            type: 'total',
            actual_amount: expense.totals.actual_amount,
            budget_amount: expense.totals.budget_amount,
            diff_amount: expense.totals.diff_amount,
            diff_percentage: expense.totals.diff_percentage,
            full_year_budget_amount: expense.totals.full_year_budget_amount,
            full_year_budget_percentage_of_full_year: expense.totals.full_year_budget_percentage_of_full_year,
            full_year_prognosis: expense.totals.full_year_prognosis
          })
        }
        if (income) {
          summaryData.push({ account_title: 'income', type: 'normal', actual_amount: income.totals.actual_amount, budget_amount: income.totals.budget_amount, diff_amount: income.totals.diff_amount, diff_percentage: income.totals.diff_percentage, full_year_budget_amount: income.totals.full_year_budget_amount, full_year_budget_percentage_of_full_year: income.totals.full_year_budget_percentage_of_full_year, full_year_prognosis: income.totals.full_year_prognosis })
        }
        if (expense) {
          summaryData.push({ account_title: 'expense', type: 'normal', actual_amount: expense.totals.actual_amount, budget_amount: expense.totals.budget_amount, diff_amount: expense.totals.diff_amount, diff_percentage: expense.totals.diff_percentage, full_year_budget_amount: expense.totals.full_year_budget_amount, full_year_budget_percentage_of_full_year: expense.totals.full_year_budget_percentage_of_full_year, full_year_prognosis: expense.totals.full_year_prognosis })
        }
        if (totals) {
          summaryData.push({ account_title: 'total', type: 'total', actual_amount: totals.actual_amount, budget_amount: totals.budget_amount, diff_amount: totals.diff_amount, diff_percentage: totals.diff_percentage, full_year_budget_amount: totals.full_year_budget_amount, full_year_budget_percentage_of_full_year: totals.full_year_budget_percentage_of_full_year, full_year_prognosis: totals.full_year_prognosis })
        }

        self.tableDataCollection.income = incomeData
        self.tableDataCollection.expense = expenseData
        self.tableDataCollection.summary = summaryData
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.period-budget-report-full-year-comparison-detailed-table{
}
</style>
