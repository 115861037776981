<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="period" :class="{selected: activeTab === 'period'}"  @click="onTabClick">
          {{ $t(translationPath + 'period') }}
        </b-link> <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="period_detailed_income" :class="{selected: activeTab === 'period_detailed_income'}"  @click="onTabClick">
          {{ $t(translationPath + 'period_detailed_income') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="period_detailed_expense" :class="{selected: activeTab === 'period_detailed_expense'}"  @click="onTabClick">
          {{ $t(translationPath + 'period_detailed_expense') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="full_year_comparison" :class="{selected: activeTab === 'full_year_comparison'}"  @click="onTabClick">
          {{ $t(translationPath + 'full_year_comparison') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="full_year_comparison_detailed_income" :class="{selected: activeTab === 'full_year_comparison_detailed_income'}"  @click="onTabClick">
          {{ $t(translationPath + 'full_year_comparison_detailed_income') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="full_year_comparison_detailed_expense" :class="{selected: activeTab === 'full_year_comparison_detailed_expense'}"  @click="onTabClick">
          {{ $t(translationPath + 'full_year_comparison_detailed_expense') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: 'overview'
    }
  },
  name: 'PeriodBudgetReportTabs',
  data () {
    return {
      activeTab: null,
      translationPath: 'reports.budget.period-report.tabs.'
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
