<template>
  <b-container fluid class="period-budget-report-chart-summary-wrapper pb-5">

    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'period-budget-report-chart-summary'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'PeriodBudgetReportChartSummary',
  props: ['currency_iso', 'currency', 'apiData', 'isLoaded'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.',
      chartData: {}
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      immediate: true,
      deep: true
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || !Object.keys(this.apiData).length) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      const lblBudget = this.$t('reports.budget.period-report.period.table.budget_amount')
      const lblActualAmount = this.$t('reports.budget.period-report.period.table.actual_amount')
      const lblDiffAmount = this.$t('reports.budget.period-report.period.table.diff_amount')
      const suffix = ' ' + this.currency

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        switch (tooltipItem.datasetIndex) {
          case 0 :
            return lblBudget + ': ' + formatNumberToFull(tooltipItem.value) + suffix
          case 1 :
            return lblActualAmount + ': ' + formatNumberToFull(tooltipItem.value) + suffix
          case 2 :
            return lblDiffAmount + ': ' + formatNumberToFull(tooltipItem.value) + suffix
        }
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      const labels = []
      labels.push(this.$t('common.incomes'))
      labels.push(this.$t('common.expenses'))
      labels.push(this.$t('common.result'))

      const incomeActualAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('income') > -1 &&
        this.apiData.income &&
        Object.keys(this.apiData.income).indexOf('totals') > -1 &&
        this.apiData.income.totals &&
        Object.keys(this.apiData.income.totals).indexOf('actual_amount') > -1 &&
        this.apiData.income.totals.actual_amount
          ? this.apiData.income.totals.actual_amount
          : 0
      const expenseActualAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('expense') > -1 &&
        this.apiData.expense &&
        Object.keys(this.apiData.expense).indexOf('totals') > -1 &&
        this.apiData.expense.totals &&
        Object.keys(this.apiData.expense.totals).indexOf('actual_amount') >
          -1 &&
        this.apiData.expense.totals.actual_amount
          ? this.apiData.expense.totals.actual_amount
          : 0
      const totalActualAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('totals') > -1 &&
        this.apiData.totals &&
        Object.keys(this.apiData.totals).indexOf('actual_amount') > -1 &&
        this.apiData.totals.actual_amount
          ? this.apiData.totals.actual_amount
          : 0

      // Budget amount
      const incomeBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('income') > -1 &&
        this.apiData.income &&
        Object.keys(this.apiData.income).indexOf('totals') > -1 &&
        this.apiData.income.totals &&
        Object.keys(this.apiData.income.totals).indexOf('budget_amount') > -1 &&
        this.apiData.income.totals.budget_amount
          ? this.apiData.income.totals.budget_amount
          : 0
      const expenseBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('expense') > -1 &&
        this.apiData.expense &&
        Object.keys(this.apiData.expense).indexOf('totals') > -1 &&
        this.apiData.expense.totals &&
        Object.keys(this.apiData.expense.totals).indexOf('budget_amount') >
          -1 &&
        this.apiData.expense.totals.budget_amount
          ? this.apiData.expense.totals.budget_amount
          : 0
      const totalBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('totals') > -1 &&
        this.apiData.totals &&
        Object.keys(this.apiData.totals).indexOf('budget_amount') > -1 &&
        this.apiData.totals.budget_amount
          ? this.apiData.totals.budget_amount
          : 0
      // diff amount
      const incomeDiffAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('income') > -1 &&
        this.apiData.income &&
        Object.keys(this.apiData.income).indexOf('totals') > -1 &&
        this.apiData.income.totals &&
        Object.keys(this.apiData.income.totals).indexOf('diff_amount') > -1 &&
        this.apiData.income.totals.diff_amount
          ? this.apiData.income.totals.diff_amount
          : 0
      const expenseDiffAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('expense') > -1 &&
        this.apiData.expense &&
        Object.keys(this.apiData.expense).indexOf('totals') > -1 &&
        this.apiData.expense.totals &&
        Object.keys(this.apiData.expense.totals).indexOf('diff_amount') >
          -1 &&
        this.apiData.expense.totals.diff_amount
          ? this.apiData.expense.totals.diff_amount
          : 0
      const totalDiffAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('totals') > -1 &&
        this.apiData.totals &&
        Object.keys(this.apiData.totals).indexOf('diff_amount') > -1 &&
        this.apiData.totals.diff_amount
          ? this.apiData.totals.diff_amount
          : 0

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: lblBudget,
            data: [incomeBudgetAmount.toFixed(0), (expenseBudgetAmount * -1).toFixed(0), totalBudgetAmount.toFixed(0)],
            type: 'bar',
            backgroundColor: '#9a9bae'
          },
          {
            label: lblActualAmount,
            data: [incomeActualAmount.toFixed(0), (expenseActualAmount * -1).toFixed(0), totalActualAmount.toFixed(0)],
            type: 'bar',
            backgroundColor: '#8cccad'
          },
          {
            label: lblDiffAmount,
            data: [incomeDiffAmount.toFixed(0), (expenseDiffAmount.diff_amount * -1).toFixed(0), totalDiffAmount.toFixed(0)],
            type: 'bar',
            backgroundColor: '#C0C0C0'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
