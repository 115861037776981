<template>
  <b-container fluid class="period-budget-report-chart-full-year-comparison-summary pb-5">

    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'period-budget-report-chart-summary'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'PeriodBudgetReportChartFullYearComparisonSummary',
  props: ['currency_iso', 'currency', 'apiData', 'isLoaded'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.',
      chartData: {}
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || !Object.keys(this.apiData).length) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      const lblBudget = this.$t('reports.budget.period-report.period.table.budget_amount')
      const lblPrognosis = this.$t('reports.budget.period-report.period.table.full_year_prognosis')
      const suffix = ' ' + this.currency

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        switch (tooltipItem.datasetIndex) {
          case 0 :
            return lblPrognosis + ': ' + formatNumberToFull(tooltipItem.value) + suffix
          case 1 :
            return lblBudget + ': ' + formatNumberToFull(tooltipItem.value) + suffix
        }
      }

      this.chartOptions.scales.xAxes = [{
        stacked: true,
        display: true,
        id: 'bar-x-axis1'
      }, {
        stacked: true,
        display: false,
        id: 'bar-x-axis2',
        type: 'category',
        offset: true
      }]

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      const labels = []
      labels.push(this.$t('common.incomes'))
      labels.push(this.$t('common.expenses'))
      labels.push(this.$t('common.result'))

      const incomeFullYearAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('income') > -1 &&
        this.apiData.income &&
        Object.keys(this.apiData.income).indexOf('totals') > -1 &&
        this.apiData.income.totals &&
        Object.keys(this.apiData.income.totals).indexOf('full_year_prognosis') > -1 &&
        this.apiData.income.totals.full_year_prognosis
          ? this.apiData.income.totals.full_year_prognosis
          : 0
      const expenseFullYearAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('expense') > -1 &&
        this.apiData.expense &&
        Object.keys(this.apiData.expense).indexOf('totals') > -1 &&
        this.apiData.expense.totals &&
        Object.keys(this.apiData.expense.totals).indexOf('full_year_prognosis') >
          -1 &&
        this.apiData.expense.totals.full_year_prognosis
          ? this.apiData.expense.totals.full_year_prognosis
          : 0
      const totalFullYearAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('totals') > -1 &&
        this.apiData.totals &&
        Object.keys(this.apiData.totals).indexOf('full_year_prognosis') > -1 &&
        this.apiData.totals.full_year_prognosis
          ? this.apiData.totals.full_year_prognosis
          : 0

      // Budget amount
      const incomeBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('income') > -1 &&
        this.apiData.income &&
        Object.keys(this.apiData.income).indexOf('totals') > -1 &&
        this.apiData.income.totals &&
        Object.keys(this.apiData.income.totals).indexOf('full_year_budget_amount') > -1 &&
        this.apiData.income.totals.full_year_budget_amount
          ? this.apiData.income.totals.full_year_budget_amount
          : 0
      const expenseBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('expense') > -1 &&
        this.apiData.expense &&
        Object.keys(this.apiData.expense).indexOf('totals') > -1 &&
        this.apiData.expense.totals &&
        Object.keys(this.apiData.expense.totals).indexOf('full_year_budget_amount') >
          -1 &&
        this.apiData.expense.totals.full_year_budget_amount
          ? this.apiData.expense.totals.full_year_budget_amount
          : 0
      const totalBudgetAmount =
        this.apiData &&
        Object.keys(this.apiData).indexOf('totals') > -1 &&
        this.apiData.totals &&
        Object.keys(this.apiData.totals).indexOf('full_year_budget_amount') > -1 &&
        this.apiData.totals.full_year_budget_amount
          ? this.apiData.totals.full_year_budget_amount
          : 0

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: lblPrognosis,
            data: [incomeFullYearAmount.toFixed(0), (expenseFullYearAmount * -1).toFixed(0), totalFullYearAmount.toFixed(0)],
            type: 'bar',
            backgroundColor: 'rgba(255, 202, 40, 0.7)',
            borderWidth: 1,
            barPercentage: 0.8,
            xAxisID: 'bar-x-axis2'
          },
          {
            label: lblBudget,
            data: [incomeBudgetAmount.toFixed(0), (expenseBudgetAmount * -1).toFixed(0), totalBudgetAmount.toFixed(0)],
            type: 'bar',
            backgroundColor: 'rgba(155, 154, 174, 0.4)',
            borderWidth: 1,
            barPercentage: 1.0,
            xAxisID: 'bar-x-axis1'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
.period-budget-report-chart-full-year-comparison-summary{

}
</style>
