<template>
  <div class="period-budget-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="7" md="8" xl="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'report_title') }}</h1>
            </b-col>
            <b-col cols="12" sm="5" md="4" xl="3" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isPeriodSelectorShown"
                :show-predefined-periods="true"
                :show-resolution="false"
                :show-last-months="true"
                :default-start-date="period_start"
                :default-end-date="period_end"
                :default-resolution="resolution"
                @period-selected="hanldeChangeDate"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <period-budget-report-tabs :activeTab="activeTab" @tab-switched="onTabSwitched" ref="Tabmenu"/>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" style="width:100%;height:500px;">
          <period-budget-report-chart-summary v-if="activeTab === 'period1'" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-summary>
          <period-budget-report-chart-summary-overlapping v-if="activeTab === 'period'" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-summary-overlapping>
          <period-budget-report-chart-detailed-summary v-if="activeTab === 'period_detailed_income'" categoryType="income" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-detailed-summary>
          <period-budget-report-chart-detailed-summary v-if="activeTab === 'period_detailed_expense'" categoryType="expense" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-detailed-summary>
          <period-budget-report-chart-full-year-comparison-summary v-if="activeTab === 'full_year_comparison'" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-full-year-comparison-summary>
          <period-budget-report-chart-full-year-comparison-detailed v-if="activeTab === 'full_year_comparison_detailed_income'" categoryType="income" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-full-year-comparison-detailed>
          <period-budget-report-chart-full-year-comparison-detailed v-if="activeTab === 'full_year_comparison_detailed_expense'" categoryType="expense" :apiData="apiData" :currency="currentCOA.currency" :currency_iso="currentCOA.currency_iso" :is-loaded="!busyState"></period-budget-report-chart-full-year-comparison-detailed>
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 pb-0">
      <period-budget-report-period-table
        v-if="activeTab === 'period'"
        :startDate="period_start"
        :endDate="period_end"
        :apiData="apiData"
        :budget-id="budgetId"
        :budgets="budgets"
        :busyState="busyState"
        @on-budget-change="onBudgetChange"
      />
      <period-budget-report-period-detailed-table
        v-if="activeTab === 'period_detailed_income' || activeTab === 'period_detailed_expense'"
        :startDate="period_start"
        :endDate="period_end"
        :apiData="apiData"
        :budget-id="budgetId"
        :budgets="budgets"
        :busyState="busyState"
        @on-budget-change="onBudgetChange"
      />
      <period-budget-report-full-year-comparison-table
        v-if="activeTab === 'full_year_comparison'"
        :startDate="period_start"
        :endDate="period_end"
        :comparisonYear="comparison_year"
        :apiData="apiData"
        :budget-id="budgetId"
        :budgets="budgets"
        :busyState="busyState"
        @on-budget-change="onBudgetChange"
        />
      <period-budget-report-full-year-comparison-detailed-table
        v-if="activeTab === 'full_year_comparison_detailed_income' || activeTab === 'full_year_comparison_detailed_expense'"
        :startDate="period_start"
        :endDate="period_end"
        :comparisonYear="comparison_year"
        :apiData="apiData"
        :budget-id="budgetId"
        :budgets="budgets"
        :busyState="busyState"
        @on-budget-change="onBudgetChange"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodBudgetReportTabs from './PeriodBudgetReportTabs'
import PeriodBudgetReportPeriodTable from './PeriodBudgetReportPeriodTable'
import PeriodBudgetReportPeriodDetailedTable from './PeriodBudgetReportPeriodDetailedTable'
import PeriodBudgetReportFullYearComparisonTable from './PeriodBudgetReportFullYearComparisonTable'
import PeriodBudgetReportFullYearComparisonDetailedTable from './PeriodBudgetReportFullYearComparisonDetailedTable'
import PeriodBudgetReportChartSummary from './PeriodBudgetReportChartSummary'
import PeriodBudgetReportChartSummaryOverlapping from './PeriodBudgetReportChartSummaryOverlapping'
import PeriodBudgetReportChartDetailedSummary from './PeriodBudgetReportChartDetailedSummary'
import PeriodBudgetReportChartFullYearComparisonSummary from './PeriodBudgetReportChartFullYearComparisonSummary'
import PeriodBudgetReportChartFullYearComparisonDetailed from './PeriodBudgetReportChartFullYearComparisonDetailed'
import PeriodSelector from '@/components/common/PeriodSelector'
import axios from 'axios'

export default {
  name: 'PeriodBudgetReport',
  mixins: [titleMixins],
  components: {
    PeriodBudgetReportChartDetailedSummary,
    PeriodBudgetReportChartSummary,
    PeriodBudgetReportChartSummaryOverlapping,
    PeriodBudgetReportFullYearComparisonDetailedTable,
    PeriodBudgetReportFullYearComparisonTable,
    PeriodBudgetReportPeriodTable,
    PeriodBudgetReportTabs,
    PeriodBudgetReportPeriodDetailedTable,
    PeriodBudgetReportChartFullYearComparisonSummary,
    PeriodBudgetReportChartFullYearComparisonDetailed,
    PeriodSelector
  },
  filters: {},
  data () {
    return {
      activeTab: null,
      chartData: [],
      budgets: null,
      budgetId: null,
      apiData: {
        income: null,
        expense: null,
        totals: {
          actual_amount: 0,
          budget_amount: 0,
          diff_amount: 0,
          diff_percentage: 0,
          full_year_budget_amount: 0,
          full_year_budget_percentage_of_full_year: 0,
          full_year_prognosis: 0
        }
      },
      busyState: false,
      translationPath: 'reports.budget.period-report.',
      period_start: null,
      period_end: null,
      comparison_year: null,
      resolution: 'year',
      isPeriodSelectorShown: false
    }
  },
  async created () {
    if (this.$route.query.budget) {
      this.budgetId = Number(this.$route.query.budget)
    } else {
      this.budgetId = this.currentCOA.default_budget_id || null
    }
    this.loadBudgets()

    let yearWithBalance = await this.fetchInitialYears()
    if (!yearWithBalance) {
      yearWithBalance = new Date().getFullYear()
    }
    const currentYearFirstDate = new Date(`01/01/${yearWithBalance}`)
    this.period_start = this.$moment(currentYearFirstDate).format('YYYY-MM-DD')
    this.period_end = this.$moment(currentYearFirstDate).endOf('year').format('YYYY-MM-DD')
    this.isPeriodSelectorShown = true
    this.comparison_year = this.$moment(currentYearFirstDate).endOf('year').format('YYYY')
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    this.$watch(vm => [vm.period_start, vm.period_end, vm.budgetId], () => {
      if (this.period_start && this.period_end && this.budgetId) {
        this.fetchDataFromApi(this.period_start, this.period_end, this.budgetId)
      }
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.budget_reports'), to: '/reports/budget' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'report_title')
    }
  },
  methods: {
    hanldeChangeDate (eventData) {
      this.period_start = eventData.sDateStart
      this.period_end = eventData.sDateEnd
      this.resolution = eventData.resolution
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        const pushObj = {
          name: 'period-budget-report',
          params: { tab: newtab }
        }
        if (this.$route.query.budget) {
          pushObj.query = { budget: this.budgetId }
        }
        this.$router.push(pushObj)
      }
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    changeTab (tab) {
      if (['period', 'period_detailed_income', 'period_detailed_expense', 'full_year_comparison_detailed_income', 'full_year_comparison_detailed_expense', 'full_year_comparison'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
      }
    },
    fetchInitialYears () {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/years-for-component`)
        .then((response) => response.data.data)
        .then((res) => {
          // eslint-disable-next-line camelcase
          const { years_with_existing_budget_values } = res
          // eslint-disable-next-line camelcase
          if (!years_with_existing_budget_values || years_with_existing_budget_values.length === 0) {
            return null
          }
          return years_with_existing_budget_values[0]
        })
        .catch((e) => {
          console.error(e)
          return null
        })
    },
    fetchDataFromApi (startDate, endDate, budgetId) {
      this.busyState = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/reports/budget/period/with/prognosis?start_date=${startDate}&end_date=${endDate}&budget_id=${budgetId}`)
        .then((res) => res.data.data)
        .then((res) => {
          const { income, expense, totals } = res
          this.apiData = {
            income: income,
            expense: expense,
            totals: totals || {
              actual_amount: 0,
              budget_amount: 0,
              diff_amount: 0,
              diff_percentage: 0,
              full_year_budget_amount: 0,
              full_year_budget_percentage_of_full_year: 0,
              full_year_prognosis: 0
            }
          }
          this.busyState = false
        })
        .catch((err) => {
          console.error(err)
          this.apiData = {
            income: null,
            expense: null,
            totals: null
          }
          this.busyState = false
        })
    },
    onBudgetChange (budgetId) {
      this.budgetId = budgetId
      if (Number(this.$route.query.budget !== budgetId)) {
        this.$router.push({ params: { tab: this.$route.params.tab }, query: { budget: budgetId } })
      }
    }
  },
  watch: {
    'currentCOA.default_budget_id' () {
      if (!this.budgetId) {
        this.budgetId = this.currentCOA.default_budget_id
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
.period-budget-report {
  width: 100%;

  /* Normal table - PeriodTable + PeriodDetailedTable */
  .period-budget-report-table-wrapper, .period-budget-report-comparison-year-table-wrapper{
    tr {
      td {
        &:nth-child(1) {
          font-weight: 400 !important;
        }
        &:nth-child(2) { width:20%; }
        &:nth-child(3) { width:20%; }
        &:nth-child(4) { width:20%; }
        &:nth-child(5) { width:20%; }
      }
      &.tr-account-group {
        background: #fff !important;
        border-top: none;
        border-bottom: 1px solid #000;
        td {
          border-top: none;
        }
      }
      &.tr-subtotal {
        border-top: 1px solid #ACAEBA;
        border-bottom: 2px solid #ACAEBA;
        font-weight: 900;
      }
      &.tr-total {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        td:nth-child(1) {
          font-weight: 900 !important;
        }
        td {
          font-weight: 900 !important;
        }
      }
      &.tr-empty-row {
        border: none;
        background: #fff !important;
      }
      &.tr-accounts {
        font-weight: 400;
        td:nth-child(1){
          font-weight: 400;
        }
      }
    }
  }

  .period-budget-report-comparison-year-table-wrapper {
    tr {
      td {
        &:nth-child(1) {
          font-weight: 400 !important;
        }
        &:nth-child(2) { width: 10%;}
        &:nth-child(3) { width: 10%;}
        &:nth-child(4) { width: 10%;}
        &:nth-child(5) { width: 10%;}
        &:nth-child(6) { width: 10%;}
        &:nth-child(7) { width: 10%;}
        &:nth-child(8) { width: 10%;}
      }
    }
  }
}
</style>
