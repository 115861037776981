<template>
  <div class="period-budget-report-period-table">

    <b-container fluid class="period-budget-report-table-wrapper px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col lg="8">
                  <h2>{{ $t(translationPath + 'title_accounts', {
                    period_start: startDate,
                    period_end: endDate
                  }) }}</h2>
                </b-col>
                <b-col lg="4">
                  <toggle-budgets-dropdown :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <b-row no-gutters>
              <b-col lg="8">
                <div v-html="$t(translationPath + 'description_period_table_total')"></div>
              </b-col>
            </b-row>

            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableDataCollection['summary']"
              :fields="fieldValues"
              :busy="busyState"
              :tbodyTrClass="tbodyTrClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t('common.result')}}
                </template>
                <template v-else>
                  {{ $t(`common.${row.value}`+'s') }}
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <span v-if="row.item.account_title === 'expense'" :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.value }}</span>
                <span v-else :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.value }}</span>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <span v-if="row.item.account_title === 'expense'" :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
                <span v-else :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="period-budget-report-table-wrapper px-0 settings-tab mt-4">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'title_groups_income', { period_start: startDate, period_end: endDate}) }}</h2>
            </template>

            <p class="col-lg-8 px-0">{{ $t(translationPath + 'description_income_groups') }}</p>
            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableDataCollection['income']"
              :fields="fieldValues"
              :busy="busyState"
              :tbodyTrClass="tbodyTrClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t(translationPath + 'sum_incomes')}}
                </template>
                <template v-else>
                  <b-link :to="'/reports/performance/account-groups/'+row.item.account_id">{{ row.value }}</b-link>
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <span v-if="row.item.account_title === 'expense'" :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.value }}</span>
                <span v-else :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.value }}</span>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <span :class="row.unformatted < 0 ?  'text-red' : row.unformatted > 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="period-budget-report-table-wrapper px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'title_groups_expenses', {
                period_start: startDate,
                period_end: endDate
              }) }}</h2>
            </template>

            <p>{{ $t(translationPath + 'description_expense_groups') }}</p>
            <b-table
              class="spirecta-simple-table report-account-table report-table-aggregated mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableDataCollection['expense']"
              :fields="fieldValues"
              :busy="busyState"
              :tbodyTrClass="tbodyTrClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>
              <template v-slot:cell(account_title)="row">
                <template v-if="row.item.account_title === 'empty_row'">
                  &nbsp;
                </template>
                <template v-else-if="row.item.account_title === 'total'">
                  {{ $t(translationPath + 'sum_expenses')}}
                </template>
                <template v-else>
                  <b-link :to="'/reports/performance/account-groups/'+row.item.account_id">{{ row.value }}</b-link>
                </template>
              </template>
              <template v-slot:cell(diff_amount)="row">
                <span :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.value }}</span>
              </template>
              <template v-slot:cell(diff_percentage)="row">
                <span :class="row.unformatted > 0 ?  'text-red' : row.unformatted < 0 ? 'text-green' : ''"> {{ row.item.budget_amount !== 0 ? row.value : '--' }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'PeriodBudgetReportPeriodTable',
  components: {
    Loader,
    ToggleBudgetsDropdown
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    busyState: { type: Boolean, default: false },
    apiData: {
      type: Object,
      default: function () {
        return {
          income: null,
          expense: null,
          totals: {
            actual_amount: 0,
            budget_amount: 0,
            diff_amount: 0,
            diff_percentage: 0,
            full_year_budget_amount: 0,
            full_year_budget_percentage_of_full_year: 0,
            full_year_prognosis: 0
          }
        }
      }
    },
    budgets: {
      type: Array,
      default: () => { return [] }
    },
    budgetId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.period.table.',
      tableDataCollection: {
        income: null,
        expense: null,
        summary: null
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'account_title', label: this.currentUser.strict_accounting_mode ? this.$t('common.account') : this.$t('common.category'), sortable: false, class: 'text-left' },
        { key: 'budget_amount', label: this.$t(this.translationPath + 'budget_amount'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'actual_amount', label: this.$t(this.translationPath + 'actual_amount'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'diff_amount', label: this.$t(this.translationPath + 'diff_amount'), sortable: false, class: 'text-left text-md-right', formatter: this.amountFormattor },
        { key: 'diff_percentage', label: this.$t(this.translationPath + 'diff_percentage'), sortable: false, class: 'text-left text-md-right', formatter: this.percentageFormatter }
      ]
    },
    local () {
      return this.currentUser.default_lang
    }
  },
  methods: {
    tbodyTrClass (item) {
      if (!item) {
        return ''
      }

      if (Object.prototype.hasOwnProperty.call(item, 'type')) {
        return 'tr-' + item.type.replace(/_/, '-')
      }

      return ''
    },
    amountFormattor (value) {
      return value ? formatAmount(
        value,
        this.currentCOA.locale,
        this.currentCOA.currency,
        this.currentCOA.currency_iso,
        false,
        0
      ) : 0
    },
    percentageFormatter (value) {
      return (value * 100).toFixed(2) + '%'
    },
    onBudgetChange (budgetId) {
      this.$emit('on-budget-change', budgetId)
    }
  },
  watch: {
    apiData: {
      handler: function (value) {
        if (!value) return
        const { income, expense, totals } = value
        const self = this
        const incomeData = []
        const expenseData = []
        const summaryData = []

        if (income) {
          Object.keys(income.groups).forEach(function (groupItem) {
            incomeData.push({
              account_title: income.groups[groupItem].group_title,
              account_id: income.groups[groupItem].group_id,
              type: 'account_group_basic',
              actual_amount: income.groups[groupItem].totals.actual_amount,
              budget_amount: income.groups[groupItem].totals.budget_amount,
              diff_amount: income.groups[groupItem].totals.diff_amount,
              diff_percentage: income.groups[groupItem].totals.diff_percentage
            })
          })
          incomeData.push({
            account_title: 'total',
            type: 'total',
            actual_amount: income.totals.actual_amount,
            budget_amount: income.totals.budget_amount,
            diff_amount: income.totals.diff_amount,
            diff_percentage: income.totals.diff_percentage
          })
        }

        if (expense) {
          Object.keys(expense.groups).forEach(function (groupItem) {
            expenseData.push({
              account_title: expense.groups[groupItem].group_title,
              account_id: expense.groups[groupItem].group_id,
              type: 'account_group_basic',
              actual_amount: expense.groups[groupItem].totals.actual_amount,
              budget_amount: expense.groups[groupItem].totals.budget_amount,
              diff_amount: expense.groups[groupItem].totals.diff_amount,
              diff_percentage: expense.groups[groupItem].totals.diff_percentage
            })
          })
          expenseData.push({
            account_title: 'total',
            type: 'total',
            actual_amount: expense.totals.actual_amount,
            budget_amount: expense.totals.budget_amount,
            diff_amount: expense.totals.diff_amount,
            diff_percentage: expense.totals.diff_percentage
          })
        }
        if (income) {
          summaryData.push({ account_title: 'income', type: 'normal', actual_amount: income.totals.actual_amount, budget_amount: income.totals.budget_amount, diff_amount: income.totals.diff_amount, diff_percentage: income.totals.diff_percentage })
        }
        if (expense) {
          summaryData.push({ account_title: 'expense', type: 'normal', actual_amount: expense.totals.actual_amount, budget_amount: expense.totals.budget_amount, diff_amount: expense.totals.diff_amount, diff_percentage: expense.totals.diff_percentage })
        }
        if (totals) {
          summaryData.push({ account_title: 'total', type: 'total', actual_amount: totals.actual_amount, budget_amount: totals.budget_amount, diff_amount: totals.diff_amount, diff_percentage: totals.diff_percentage })
        }
        self.tableDataCollection.income = incomeData
        self.tableDataCollection.expense = expenseData
        self.tableDataCollection.summary = summaryData
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.period-budget-report{
  .period-budget-report-period-table{

  }
}
</style>
