<template>
  <b-container fluid class="period-budget-report-chart-detailed-summary-wrapper pb-5">

    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'period-budget-report-chart-detailed-summary'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'PeriodBudgetReportChartDetailedSummary',
  props: ['currency_iso', 'currency', 'apiData', 'isLoaded', 'categoryType'],
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.',
      chartData: {},
      currencyPrefix: ''
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    currency: {
      handler: function (val) {
        if (val) {
          this.currencyPrefix = val
        }
      },
      deep: true,
      immediate: true
    },
    categoryType: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || !Object.keys(this.apiData).length) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      const lblBudget = this.$t('reports.budget.period-report.period.table.budget_amount')
      const lblActualAmount = this.$t('reports.budget.period-report.period.table.actual_amount')
      const _self = this
      const suffix = ' ' + this.currency

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        switch (tooltipItem.datasetIndex) {
          case 0 :
            return lblActualAmount + ': ' + formatNumberToFull(tooltipItem.value) + ' ' + _self.currencyPrefix
          case 1 :
            return lblBudget + ': ' + formatNumberToFull(tooltipItem.value) + ' ' + _self.currencyPrefix
        }
      }

      this.chartOptions.scales.xAxes = [{
        stacked: true,
        display: true,
        id: 'bar-x-axis1'
      }, {
        stacked: true,
        display: false,
        id: 'bar-x-axis2',
        type: 'category',
        offset: true
      }]

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Resort based on name
      let aGroups = this.apiData.expense ? this.apiData.expense.groups : {}
      if (this.categoryType === 'income') {
        aGroups = this.apiData.income ? this.apiData.income.groups : {}
      }
      const sortedGroups = Object.keys(aGroups).map((el) => aGroups[el]).sort(function (a, b) {
        const nameA = a.group_title
        const nameB = b.group_title
        return nameA.localeCompare(nameB, self.local)
      })

      const labels = []
      const datasetBudgetAmount = { label: lblBudget, data: [], type: 'bar', backgroundColor: 'rgba(155, 154, 174, 0.4)', borderWidth: 1, barPercentage: 1.0, xAxisID: 'bar-x-axis1' }
      const datasetActualAmount = { label: lblActualAmount, data: [], type: 'bar', backgroundColor: 'rgba(140, 204, 173, 0.7)', borderWidth: 1, barPercentage: 0.8, xAxisID: 'bar-x-axis2' }

      for (var index in sortedGroups) {
        labels.push(sortedGroups[index].group_title)
        datasetBudgetAmount.data.push(sortedGroups[index].totals.budget_amount.toFixed(0))
        datasetActualAmount.data.push(sortedGroups[index].totals.actual_amount.toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [datasetActualAmount, datasetBudgetAmount]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
